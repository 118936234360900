/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { FiMessageCircle } from 'react-icons/fi';

import http from '../../services/http';

import SolicitudesMensajesItem from './SolicitudesMensajesItem';

import './SolicitudesMensajes.scss';

const SolicitudesMensajes = (props) => {
  const { solicitudId, agregar } = props;

  const [form] = Form.useForm();

  const [mensajes, setMensajes] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    async function getMensajes() {
      const response = await http.get(
        `micrositio/perfil/solicitudes/${solicitudId}/mensajes`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setMensajes(data.mensajes);
      }
    }
    getMensajes();
  }, [reload]);

  const onSubmitForm = async (values) => {
    if (!values.contenido) {
      return;
    }

    values.contenido = values.contenido.toUpperCase();
    values.solicitud = solicitudId;
    form.resetFields();

    const response = await http.post(
      `micrositio/perfil/solicitudes/${solicitudId}/mensajes`,
      values
    );

    if (response && response.data.code === 0) {
      setReload(!reload);
    }
  };

  return (
    <div className="solicitudes-mensajes">
      <div className="header">
        <div className="icon">
          <FiMessageCircle />
        </div>
        <div className="data">
          <div className="title">Mensajes de Consulta</div>
          <div className="description">
            Envia tus consultas para que alguien de la Municipalidad te las resuelva.
          </div>
        </div>
      </div>

      <div className="chat">
        {mensajes.length === 0 && (
          <div className="empty">
            <p>No hay mensajes</p>
          </div>
        )}
        {mensajes.length > 0 &&
          mensajes.map((item, index) => (
            <SolicitudesMensajesItem key={index} mensaje={item} />
          ))}
      </div>

      {agregar && (
        <Form form={form} className="form" onFinish={onSubmitForm}>
          <Form.Item name="contenido">
            <Input maxLength={500} placeholder="Ingresá tu consulta..." />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form>
      )}
    </div>
  );
};

export default SolicitudesMensajes;
