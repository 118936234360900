/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import {
  FiDollarSign,
  FiHome,
  FiPower,
  FiEdit2,
  FiFolder,
  FiThumbsDown,
} from "react-icons/fi";

import { clearData, getUser } from "./../../services/security";

import icon from "./../../assets/images/user.png";
import logo from "./../../assets/images/logo-horizontal.png";

import "./Navbar.scss";

const Navbar = () => {
  const history = useHistory();

  const user = getUser();

  const onLogout = () => {
    clearData();
    window.location.replace("/");
  };

  const menuUser = (
    <Menu className="navbar-actions">
      <Menu.Item key="1" onClick={() => history.push("/")}>
        <FiHome size="1.5em" />
        Inicio
      </Menu.Item>
      <Menu.Item key="2" onClick={() => history.push("/perfil/datos")}>
        <FiEdit2 size="1.5em" />
        Mis Datos
      </Menu.Item>
      <Menu.Item key="3" onClick={() => history.push("/perfil/documentos")}>
        <FiFolder size="1.5em" />
        Mis Documentos
      </Menu.Item>
      <Menu.Item key="4" onClick={() => history.push("/perfil/contrataciones")}>
        <FiDollarSign size="1.5em" />
        Mis Contrataciones
      </Menu.Item>
      <Menu.Item key="5" onClick={() => history.push("/perfil/solicitud-baja")}>
        <FiThumbsDown size="1.5em" />
        Solicitar Baja Voluntaria
      </Menu.Item>
      <Menu.Item key="6" onClick={onLogout}>
        <FiPower size="1.5em" />
        Cerrar Sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="navbar">
      <div className="container">
        <a href="/">
          <img className="logo" src={logo} alt="logo" />
        </a>
        {user !== null && (
          <Dropdown
            overlay={menuUser}
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
            <div className="user">
              <div className="data">
                <p className="name">{user?.nombre}</p>
              </div>
              <img className="icon" src={icon} alt="user" />
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default Navbar;
