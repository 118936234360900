import React from "react";
import { useHistory } from "react-router-dom";

import "./Secciones.scss";

const Secciones = () => {
  const history = useHistory();

  const seccion = window.location.href.split("/").pop();

  return (
    <div className="secciones">
      <div className="container">
        <ul>
          <li
            className={`${seccion === "datos" ? "active" : ""}`}
            onClick={() => history.push("/perfil/datos")}
          >
            <div>Datos</div>
          </li>
          <li
            className={`${seccion === "documentos" ? "active" : ""}`}
            onClick={() => history.push("/perfil/documentos")}
          >
            Documentos
          </li>
          <li
            className={`${seccion === "licitaciones" ? "active" : ""}`}
            onClick={() => history.push("/perfil/contrataciones")}
          >
            Contrataciones
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Secciones;
