export const setData = (data) => {
  localStorage.setItem("token", data.token);
  localStorage.setItem("user", JSON.stringify(data.user));
};

export const clearData = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

export const getUser = () => {
  const user = localStorage.getItem("user");
  return user === null ? null : JSON.parse(user);
};

export const getJwt = () => {
  return localStorage.getItem("token");
};
