/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tag } from "antd";

const SolicitudEstado = (props) => {
  const { estado } = props;

  const [color, setColor] = useState();
  const [text, setText] = useState();

  useEffect(() => {
    switch (estado) {
      case 1:
        setColor("gold");
        setText("Creada");
        break;

      case 2:
        setColor("lime");
        setText("Vigente");
        break;

      case 3:
        setColor("green");
        setText("Apertura");
        break;

      case 4:
        setColor("cyan");
        setText("Post Apertura");
        break;

      case 5:
        setColor("blue");
        setText("Pre Adjudicación");
        break;

      case 6:
        setColor("geekblue");
        setText("Adjudicada");
        break;

      case 7:
        setColor("purple");
        setText("Finalizada");
        break;

      default:
        setColor("red");
        setText("Observada");
        break;
    }
  }, []);

  return <Tag color={color}>{text}</Tag>;
};

export default SolicitudEstado;
