/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import { FiDownload, FiEye, FiFolderPlus } from "react-icons/fi";
import { FileIcon, defaultStyles } from "react-file-icon";

import { readFile } from "./../../services/files";
import http from "./../../services/http";
import { showSuccess } from "./../../services/notification";

import "./SolicitudesDocumentosAdicionales.scss";
import Viewer from "react-viewer";

const SolicitudesDocumentosAdicionales = (props) => {
  const { solicitudId, agregar } = props;

  const fileRef = useRef(null);

  const [documentosAdicionales, setDocumentosAdicionales] = useState([]);
  const [reload, setReload] = useState(false);

  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);


  const images = ["jpg", "jpeg", "bmp", "gif", "png", "pdf"];

  const onCloseModalPdf = async () => {
    setPdfVisible(false);
  }

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    if (file.extension === "pdf") {
      setPdfVisible(true)
    }
    else {
      setPreviewVisible(true);
    }
  };


  useEffect(() => {
    async function getList() {
      const url = `micrositio/perfil/solicitudes/${solicitudId}/documentosadicionales`;
      const response = await http.get(url);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDocumentosAdicionales(data.documentosAdicionales);
      }
    }
    getList();
  }, [reload]);

  const onDownload = (item) => {
    if (!item.id) {
      return;
    }

    var win = window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${item.id}`,
      "_blank"
    );
    win.focus();
  };

  const onDownloadAll = () => {
    var win = window.open(
      `${process.env.REACT_APP_API_URL}/archivos/solicitud/${solicitudId}`,
      "_blank"
    );
    win.focus();
  };

  const onClickAdd = () => {
    fileRef.current.click();
  };

  const onFileChange = async (e) => {
    const files = e.currentTarget.files;
    if (files.length === 0) {
      return;
    }

    let file = files[0];

    const base64 = await readFile(file);

    const url = `micrositio/perfil/solicitudes/${solicitudId}/documentosadicionales`;
    const data = {
      nombre: file.name,
      tamanio: file.size,
      contenido: base64.split(",")[1],
    };

    const response = await http.post(url, data);
    if (response && response.data.code === 0) {
      showSuccess(response.data.message);
      setReload(!reload);
    }

    fileRef.current.value = "";
  };

  return (
    <div className="solicitudes-documentos-adicionales">
      <div className="header">
        <div className="icon">
          <FiFolderPlus />
        </div>
        <div className="data">
          <div className="title">
            Documentación dentro de las 48 hs. y/u Observaciones
          </div>
          <div className="description">
            Lista de documentos cargados una vez cerrada la carpeta inicial.
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th className="icon"></th>
            <th>Nombre</th>
            <th className="download">
              {documentosAdicionales.length > 0 && (
                <div title="Descargar Todo" onClick={onDownloadAll}>
                  <FiDownload />
                </div>
              )}
            </th>
            
            <th></th>
          </tr>
        </thead>
        <tbody>
          {documentosAdicionales.map((documento, index) => (
            <tr key={index}>
              <td className="icon">
                <FileIcon
                  extension={documento.extension}
                  {...defaultStyles.pdf}
                />
              </td>
              <td>{documento.nombre}</td>
              <td className="download">
                {documento.id && (
                  <div title="Descargar" onClick={() => onDownload(documento)}>
                    <FiDownload />
                  </div>
                )}
              </td>
              
            {images.indexOf(documento.extension) > -1 && (
                <td className="download">
                  {documento.id && (
                    <div title="Ver" onClick={() => onPreview(documento)}>
                      <FiEye />
                    </div>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      
      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: "" }]}
      />

      {pdfVisible && (
        <React.Fragment>
          <Modal
            className=""
            centered
            destroyOnClose={true}
            footer={null}
            width={1200}
            visible={pdfVisible}
            onCancel={onCloseModalPdf}
          >
            <iframe title='Ver documento' src={previewUrl} frameBorder="0" width="98%" height="600"></iframe>

          </Modal>
        </React.Fragment>
      )}

      {agregar && (
        <div className="actions">
          <Button type="primary" onClick={onClickAdd}>
            Agregar
          </Button>
        </div>
      )}

      <input
        type="file"
        ref={fileRef}
        accept="application/pdf"
        onChange={onFileChange}
      />
    </div>
  );
};

export default SolicitudesDocumentosAdicionales;
