/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import {
  FiAlertTriangle,
  FiDownload,
  FiFolder,
  FiCheckCircle,
  FiUpload,
  FiEye,
} from "react-icons/fi";
import { FileIcon, defaultStyles } from "react-file-icon";

import { readFile } from "./../../services/files";
import http from "./../../services/http";
import { showSuccess } from "./../../services/notification";

import "./SolicitudesDocumentos.scss";
import Viewer from "react-viewer";

const SolicitudesDocumentos = (props) => {
  const { solicitudId, agregar, actualizar } = props;

  const fileRef = useRef(null);

  const [documentos, setDocumentos] = useState([]);
  const [current, setCurrent] = useState();
  const [reload, setReload] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);


  const images = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".pdf"];

  const onCloseModalPdf = async () => {
    setPdfVisible(false);
  }

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    if (file.extension === ".pdf") {
      setPdfVisible(true)
    }
    else {
      setPreviewVisible(true);
    }
  };

  useEffect(() => {
    async function getList() {
      const url = `micrositio/perfil/solicitudes/${solicitudId}/documentos`;
      const response = await http.get(url);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDocumentos(data.documentos);
      }
    }
    getList();
  }, [reload]);

  const onDownload = (item) => {
    if (!item.id) {
      return;
    }

    var win = window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${item.id}`,
      "_blank"
    );
    win.focus();
  };

  const onDownloadAll = () => {
    var win = window.open(
      `${process.env.REACT_APP_API_URL}/archivos/solicitud/${solicitudId}`,
      "_blank"
    );
    win.focus();
  };

  const onClickAdd = () => {
    setCurrent();
    fileRef.current.click();
  };

  const onClickUpdate = (item) => {
    setCurrent(item);
    fileRef.current.click();
  };

  const onFileChange = async (e) => {
    const files = e.currentTarget.files;
    if (files.length === 0) {
      setCurrent();
      return;
    }

    let file = files[0];

    const base64 = await readFile(file);

    const url = `micrositio/perfil/solicitudes/${solicitudId}/documentos`;
    const data = {
      nombre: file.name,
      tamanio: file.size,
      contenido: base64.split(",")[1],
      documento: current?.documento?.toString(),
    };

    const response = await http.post(url, data);
    if (response && response.data.code === 0) {
      showSuccess(response.data.message);
      setReload(!reload);
      setCurrent();
    }
    if (response && response.data.code === 1) {
      setTimeout(() => {
        window.location.reload();
      }, 2000)
    }
    fileRef.current.value = "";
  };

  return (
    <div className="solicitud-documentos">
      <div className="header">
        <div className="icon">
          <FiFolder />
        </div>
        <div className="data">
          <div className="title">Documentación</div>
          <div className="description">
            Lista de documentos que tenés que cargar para poder participar de
            esta contratación.
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th className="icon"></th>
            <th>Nombre</th>
            {/* <th className="size">Tamaño</th> */}
            <th className="date">Fecha</th>
            <th className="download">
              {documentos.length > 0 && (
                <div title="Descargar Todo" onClick={onDownloadAll}>
                  <FiDownload />
                </div>
              )}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {documentos.map((documento, index) => (
            <tr key={index}>
              <td className="icon">
                {!documento.id && (
                  <div className="warning">
                    <FiAlertTriangle />
                  </div>
                )}
                {documento.id && (
                  <FileIcon
                    extension={documento.extension}
                    {...defaultStyles.pdf}
                  />
                )}
              </td>
              <td >{documento.nombre}
                &nbsp;
                {documento.firmado && (
                  <div>
                    <FiCheckCircle title="Firmado" color="green" />
                    <small>Firmado</small>
                  </div>
                )}

              </td>
              {/* <td className="size">{documento.tamanioTexto}</td> */}
              <td className="date">{documento.fecha}</td>
              <td className="download">
                {documento.id && (
                  <div title="Descargar" onClick={() => onDownload(documento)}>
                    <FiDownload />
                  </div>
                )}
                {images.indexOf(documento.extension) > -1 && (
                  documento.id && (
                    <div title="Ver" onClick={() => onPreview(documento)}>
                      <FiEye />
                    </div>
                  )
                )}
              </td>

              <td className="update">
                {actualizar && documento.documento && (
                  <div
                    title="Actualizar"
                    onClick={() => onClickUpdate(documento)}
                  >
                    <FiUpload />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: "" }]}
      />

      {pdfVisible && (
        <React.Fragment>
          <Modal
            className=""
            centered
            destroyOnClose={true}
            footer={null}
            width={1200}
            visible={pdfVisible}
            onCancel={onCloseModalPdf}
          >
            <iframe title='Ver documento' src={previewUrl} frameBorder="0" width="98%" height="600"></iframe>

          </Modal>
        </React.Fragment>
      )}

      {agregar && (
        <div className="actions">
          <Button type="primary" onClick={onClickAdd}>
            Agregar
          </Button>
        </div>
      )}

      <input
        type="file"
        ref={fileRef}
        accept="application/pdf"
        onChange={onFileChange}
      />
    </div>
  );
};

export default SolicitudesDocumentos;
