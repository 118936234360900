/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import http from "../services/http";
import { ContextProvider } from "../store/context";

import Header from "../components/inicio/Header";
import Description from "../components/inicio/Description";
import Licitaciones from "./../components/inicio/Licitaciones";

const InicioPage = () => {
  const [state, setState] = useState({
    licitaciones: [],
    tipos: [],
    reload: false,
  });

  useEffect(() => {
    async function getData() {
      const rData = await http.get("micrositio/inicio");

      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setState({
          ...state,
          licitaciones: data.licitaciones,
          tipos: data.tipos,
        });
      }
    }

    getData();
  }, [state.reload]);

  return (
    <div className="inicio">
      <ContextProvider value={[state, setState]}>
        <Header />
        <Description />
        <Licitaciones />
      </ContextProvider>
    </div>
  );
};

export default InicioPage;
