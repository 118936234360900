import React, { useState } from "react";
import { SlideDown } from "react-slidedown";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import SolicitudEstado from "./SolicitudesEstado";
import SolicitudesDocumentos from "./SolicitudesDocumentos";
import SolicitudesDocumentosAdicionales from "./SolicitudesDocumentosAdicionales";
import SolicitudesMensajes from "./SolicitudesMensajes";
import SolicitudesDocumentosMunicipales from "./SolicitudesDocumentosMunicipales";
import SolicitudesParticipantes from "./SolicitudesParticipantes";
import ActaApertura from "./ActaApertura";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "react-slidedown/lib/slidedown.css";
import "./SolicitudesItem.scss";
import { Button, Modal } from "antd";
import http from "../../services/http";
import { showSuccess } from "../../services/notification";

const SolicitudesItem = ({ solicitud }) => {
  const [closed, setClosed] = useState(true);
  const onDelete = async () => {


    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content:
        "¿Esta seguro que desea dar de baja esta solicitud?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const response = await http.delete(`micrositio/perfil/solicitudes/${solicitud.id}`);
        if (response && response.data.code === 0) {
            showSuccess("Sus datos fueron actualizados exitosamente.");
            window.location.reload();
        } 
      },
    });


    const response = await http.delete(`micrositio/perfil/solicitudes/${solicitud.id}`);


  };
  return (
    <React.Fragment>
      <div className="solicitudes-item">
        <div className="header" onClick={() => setClosed(!closed)}>
          <div className="data">
            <div className="entity">
              Número: {solicitud.numero} - Expediente: {solicitud.expediente}
            </div>
            <div className="title">
              {solicitud.licitacion}
              <SolicitudEstado estado={solicitud.estado} />
            </div>
            <div className="date">{solicitud.fecha} </div>
          </div>
          <div className="toggle">
            {closed ? <FiChevronDown /> : <FiChevronUp />}
          </div>
        </div>
        <SlideDown className={"my-dropdown-slidedown"} closed={closed}>
          {!closed && solicitud.documentosVer && (
            <SolicitudesDocumentos
              solicitudId={solicitud.id}
              agregar={solicitud.documentosAgregar}
              actualizar={solicitud.documentosActualizar}
            />
          )}
          {!closed && solicitud.documentosAdicionalesVer && (
            <SolicitudesDocumentosAdicionales
              solicitudId={solicitud.id}
              agregar={solicitud.documentosAdicionalesAgregar}
            />
          )}
          {!closed && solicitud.mensajesVer && (
            <SolicitudesMensajes
              solicitudId={solicitud.id}
              agregar={solicitud.mensajesAgregar}
            />
          )}

          {!closed && solicitud.documentosMunicipalesVer && (
            <SolicitudesDocumentosMunicipales solicitudId={solicitud.id} />
          )}
          {!closed && solicitud.participantesVer && (
            <SolicitudesParticipantes solicitudId={solicitud.id} />
          )}
          {!closed && solicitud.documentosActaAperturaVer && (
            <ActaApertura solicitudId={solicitud.id} />
          )}
          {solicitud.solicitudEliminar && (
            <div className="actions">
            <Button type="secondary" onClick={onDelete}>
              Eliminar Solicitud
            </Button>
          </div>)}
        </SlideDown>


      </div>
    </React.Fragment>
  );
};

export default SolicitudesItem;
