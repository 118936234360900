/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Tooltip } from "antd";
import { FiCheck } from "react-icons/fi";

import icon from "./../../assets/images/user.png";

import "./SolicitudesMensajesItem.scss";

const SolicitudesMensajesItem = ({ mensaje }) => {
  return (
    <div className="mensaje">
      <div className="icon">
        <img src={icon} alt={mensaje.usuario} />
      </div>
      <div className="informacion">
        <div className="header">
          <div className="usuario">{mensaje.usuario}</div>
          <div className="fecha">{mensaje.creacion}</div>
        </div>
        <div className="contenido">{mensaje.contenido}</div>
        <div className="leido">
          {mensaje.fechaLeido && (
            <Tooltip placement="top" title={`Leido el ${mensaje.lectura}`}>
              <FiCheck />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default SolicitudesMensajesItem;
