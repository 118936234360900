/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FiDollarSign } from "react-icons/fi";

import http from "./../../services/http";

import SolicitudesItem from "./SolicitudesItem";

import "./Solicitudes.scss";

const Solicitudes = () => {
  const [solicitudes, setSolicitudes] = useState([]);

  useEffect(() => {
    async function getList() {
      const response = await http.get("micrositio/perfil/solicitudes");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setSolicitudes(data.solicitudes);
      }
    }

    getList();
  }, []);

  return (
    <div id="perfil-solicitudes" className="perfil-solicitudes">
      <div className="container">
        <div className="title">
          <h1>
            Mis Contrataciones
            <FiDollarSign />
          </h1>
          <p>
            Estas son las contrataciones en las que aplicaste y la documentación
            necesaria para cada una de ellas.
          </p>
        </div>

        <div className="solicitudes-list">
          {solicitudes.map((solicitud, index) => (
            <SolicitudesItem key={index} solicitud={solicitud} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Solicitudes;
