/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Modal, Tooltip } from "antd";
import { FiDownload, FiEye, FiSearch } from "react-icons/fi";

import { Context } from "./../../store/context";
import http from "../../services/http";

import "./DocumentosReadonly.scss";
import Viewer from "react-viewer";

const DocumentosReadonly = () => {
  const [state] = useContext(Context);

  const [documentosReadonly, setDocumentosReadonly] = useState([]);
  
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);

  const termsUrl = process.env.REACT_APP_API_URL.slice(0, -3);

  const images = ["jpg", "jpeg", "bmp", "gif", "png", "pdf"];

  const onCloseModalPdf = async () => {
    setPdfVisible(false);
  }

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    if (file.extension === "pdf") {
      setPdfVisible(true)
    }
    else {

      setPreviewVisible(true);
    }
  };


  useEffect(() => {
    async function getData() {
      const rData = await http.get(
        `micrositio/perfil/documentosReadonly?id=${state.id}`
      );

      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setDocumentosReadonly(data.files);
      }
    }

    getData();
  }, []);

  const onClickDownload = (item) => {
    var win = window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${item.id}`,
      "_blank"
    );
    win.focus();
  };

  if (documentosReadonly.length === 0) {
    return <div></div>;
  }

  return (
    <div id="perfil-documentos-readonly" className="perfil-documentos-readonly">
      <div className="container">
        <div className="title">
          <h1>
            Documentación Informativa
            <FiSearch />
          </h1>
          <p>Documentos de ayuda que te pueden ayudar con el proceso.</p>
        </div>

        <table className="documento-archivos">
          <thead>
            <tr>
              <th>Nombre</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {documentosReadonly.map((archivo, index) => (
              <tr key={index}>
                <td>{archivo.nombre}</td>
                <td>{archivo.fecha}</td>
                <td>{archivo.hora}</td>
                <td>
                  <Tooltip title="Descargar">
                    <div
                      className="documento-download"
                      onClick={() => onClickDownload(archivo)}
                    >
                      <FiDownload />
                    </div>
                  </Tooltip>
                </td>
                <td>
                    {images.indexOf(archivo.extension) > -1 && (
                      <Tooltip title="Ver">
                        <div
                          className="documento-download"
                          onClick={() =>
                            onPreview(archivo)
                          }
                        >
                          <FiEye />
                        </div>
                      </Tooltip>
                    )}
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
        
      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: "" }]}
      />

      {pdfVisible && (
        <React.Fragment>
          <Modal
            className=""
            centered
            destroyOnClose={true}
            footer={null}
            width={1200}
            visible={pdfVisible}
            onCancel={onCloseModalPdf}
          >
            <iframe title='Ver documento' src={previewUrl} frameBorder="0" width="98%" height="600"></iframe>

          </Modal>
        </React.Fragment>)}
      </div>
    </div>
  );
};

export default DocumentosReadonly;
