import React, { useState } from "react";
import { Modal } from "antd";

import { ContextProvider } from "../../store/context";

import LoginForm from "./LoginForm";
import ResetForm from "./ResetForm";
import UpdateForm from "./UpdateForm";

import "./Login.scss";
import { useHistory } from "react-router";

const Login = ({ visible, onClose, backoffice }) => {
  const history = useHistory();

  const [state, setState] = useState({
    email: "",
    current: "login",
  });
  const onCancel = () => {};

  return (
    <Modal
      className="login-form"
      centered
      destroyOnClose={true}
      footer={null}
      width={900}
      visible={visible}
      onCancel={() => (backoffice ? history.push("/") : onClose())}
    >
      <ContextProvider value={[state, setState]}>
        {state.current === "login" && (
          <LoginForm onClose={onClose} backoffice={backoffice} />
        )}
        {state.current === "reset" && (
          <ResetForm onClose={onCancel} backoffice={backoffice} />
        )}
        {state.current === "update" && <UpdateForm onClose={onClose} />}
      </ContextProvider>
    </Modal>
  );
};

export default Login;
