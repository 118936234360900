import { showWarning } from "./notification";

export const validateCuil = (cuil, sexo) => {
  cuil = cuil.replace(/\s+/g, "");

  if (cuil.length !== 11) {
    showWarning("El CUIL/CUIT debe contener 11 dígitos.");
    return false;
  }

  const prefix = cuil.substring(0, 2);
  const documento = cuil.substring(2, 10);

  if (prefix === "30" || prefix === "33" || prefix === "34") {
    sexo = "3";
  }

  var newCuil = calculateCuil(documento, sexo);

  if (cuil !== newCuil) {
    showWarning("El CUIL/CUIT ingresado no es válido.");
    return false;
  }

  return true;
};

export const addBusinessDays = (originalDate, numDaysToAdd) => {
  const Sunday = 0;
  const Saturday = 6;

  let daysRemaining = numDaysToAdd;

  const newDate = originalDate.clone();

  while (daysRemaining > 0) {
    newDate.add(1, "days");
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining--;
    }
  }

  return newDate;
};

export const calculateCuil = (documento, sexo, prefijo = "") => {
  documento = documento.padStart(8, "0");

  if (prefijo === "") {
    prefijo = sexo === "1" ? "20" : sexo === "2" ? "27" : "30";
  }

  const factores = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  const cuit = prefijo + documento;
  const valores = cuit.split("");
  let acumulado = 0;

  for (var i = 0; i < factores.length; i++) {
    var valor = parseInt(valores[i]);
    acumulado += valor * factores[i];
  }

  var digito = acumulado % 11;

  if (digito === 1) {
    if (sexo === "3") {
      return calculateCuil(documento, sexo, "33");
    } else {
      return calculateCuil(documento, sexo, "23");
    }
  } else if (digito !== 0) {
    digito = 11 - digito;
  }

  return prefijo + documento + digito.toString();
};

export const validateCbu = (cbu) => {
  if (isNaN(cbu)) {
    showWarning("El CBU solo puede contener números.");
    return false;
  }

  if (cbu.trim().length !== 22) {
    showWarning("El CBU debe contener 22 dígitos.");
    return false;
  }

  return true;
};

export const formatCurrency = (value) => {
  var isNegative = value && value < 0;

  if (isNegative) {
    value = value * -1;
    return "- $" + value.toLocaleString("es-AR", { minimumFractionDigits: 2 });
  }

  return value
    ? "$" + value.toLocaleString("es-AR", { minimumFractionDigits: 2 })
    : "-";
};

export const formatNumber = (value) => {
  if (!value) {
    return "";
  }

  if (isNaN(value)) {
    value = value.replace(",", ".");
    value = parseFloat(value);
  }

  return value > 0
    ? value.toLocaleString("es-AR", { minimumFractionDigits: 2 })
    : "";
};
