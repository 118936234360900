/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import InputMask from "react-input-mask";
import { FiEdit2, FiDownload } from "react-icons/fi";
import { FileIcon, defaultStyles } from "react-file-icon";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Context } from "./../../store/context";
import http from "../../services/http";
import { showSuccess, showWarning } from "../../services/notification";
import { required } from "../../services/validation";

import Socios from "./Socios";

import edit from "./../../assets/images/edit.png";

import "./Datos.scss";

const { Option } = Select;

const Datos = () => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);
  const urlInstructions =
    process.env.REACT_APP_API_URL.slice(0, -3) + "Instructivo_Registro.pdf";

  const [loading, setLoading] = useState(false);
  const [actividades, setActividades] = useState([]);
  const [email, setEmail] = useState();
  const [tipos, setTipos] = useState([]);
  const [sexos, setSexos] = useState([]);
  const [actualizable, setActualizable] = useState(true);
  const [sexoRequired, setSexoRequired] = useState(false);
  const [representanteRequired, setRepresentanteRequired] = useState(false);
  const [sociosVisible, setSociosVisible] = useState(false);
  const [socios, setSocios] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        `micrositio/perfil?id=${state.id ?? "nuevo"}`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;

        setEmail(data.email);
        setActividades(data.actividades);
        setSexos(data.sexos);
        setTipos(data.tipos);
        setActualizable(data.proveedor.actualizable);

        data.proveedor.tipo =
          data.proveedor.tipo && data.proveedor.tipo.toString();
        data.proveedor.sexo =
          data.proveedor.sexo && data.proveedor.sexo.toString();

        setSocios(data.proveedor.socios);

        onChangeTipo(data.proveedor.tipo);
        form.setFieldsValue(data.proveedor);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      return (event.returnValue = "Are you sure you want to exit?");
    };

    if (state.registrar) {
      window.addEventListener("beforeunload", handleTabClose);
    }

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const onChangeTipo = (value) => {
    setSociosVisible(value === "3");

    if (!value) {
      return;
    }

    sessionStorage.setItem("tipoPersona", value);

    if (value === "1") {
      form.setFieldsValue({ representanteLegal: null });
      setRepresentanteRequired(false);
      setSexoRequired(true);
      return;
    }

    form.setFieldsValue({ sexo: null });
    setRepresentanteRequired(true);
    setSexoRequired(false);
  };

  const onSave = async (values) => {
    values.nombre = values.nombre?.toUpperCase();
    values.cuil = values.cuil?.toUpperCase();
    values.representanteLegal = values.representanteLegal?.toUpperCase();
    values.direccion = values.direccion?.toUpperCase();
    values.numero = values.numero?.toUpperCase();
    values.piso = values.piso?.toUpperCase();
    values.departamento = values.departamento?.toUpperCase();

    values.direccionEspecial = values.direccionEspecial?.toUpperCase();
    values.numeroEspecial = values.numeroEspecial?.toUpperCase();
    values.pisoEspecial = values.pisoEspecial?.toUpperCase();
    values.departamentoEspecial = values.departamentoEspecial?.toUpperCase();

    values.provincia = values.provincia?.toUpperCase();
    values.localidad = values.localidad?.toUpperCase();
    values.email = values.email?.toUpperCase();
    values.confirmEmail = values.confirmEmail?.toUpperCase();
    values.telefono = values.telefono?.toUpperCase();

    values.Celular = values.Celular?.toUpperCase();

    values.socios = socios;

    if (values.tipo === "3" && values.socios.length === 0) {
      showWarning("Debe cargar los datos de al menos un socio para continuar.");
      return;
    }

    if (state.registrar) {
      if (values.email !== values.confirmEmail) {
        showWarning("Los emails ingresados no coinciden.");
        return;
      }

      values.identificador = state.id;

      setLoading(true);
      const url = "micrositio/perfil";
      const response = await http.post(url, values);
      setLoading(false);

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setState({ ...state, id: data.id, form: "documentos" });
      }

      return;
    }

    Modal.confirm({
      title: "Confirmar actualización",
      icon: <ExclamationCircleOutlined />,
      content:
        "¿Esta seguro que desea modificar sus datos? Esta acción cambiará su estado a pendiente de revisión por el personal de la Municipalidad",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        setLoading(true);
        const url = `micrositio/perfil/${state.id}`;
        const response = await http.put(url, values);
        setLoading(false);

        if (response && response.data.code === 0) {
          showSuccess("Sus datos fueron actualizados exitosamente.");
        }
      },
    });
  };

  return (
    <div id="perfil-datos" className="perfil-datos">
      <div className="container">
        {state.registrar && (
          <a
            className="instructions"
            href={urlInstructions}
            target="_blank"
            rel="noreferrer"
          >
            <div className="instructions-icon">
              <FileIcon extension=".pdf" {...defaultStyles.pdf} />
            </div>
            <div className="instructions-content">
              <div className="instructions-title">Descargar Instructivo</div>
              <div className="instructions-description">
                Si tenes dudas en como registrarte, este documento contiene una
                guía paso a paso de como darte de alta y empezar a usar esta
                plataforma.
              </div>
            </div>
            <div className="instructions-download">
              <FiDownload />
            </div>
          </a>
        )}

        {!actualizable && (
          <div className="instructions">
            <div className="instructions-icon">
              <img className="edit" src={edit} alt="edit" />
            </div>
            <div className="instructions-content">
              <div className="instructions-title">Actualizar mis datos</div>
              <div className="instructions-description">
                Si necesita modificar alguno de sus datos personales,
                comuniquesé por mail a la siguiente cuenta: {email}
              </div>
            </div>
          </div>
        )}

        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="title">
            <h1>
              Mis Datos
              <FiEdit2 />
            </h1>
            <p>Completá y actualizá el formulario con tus datos personales</p>
          </div>

          <Row gutter={[24]}>
            {!state.registrar && (
              <Col xs={24} lg={24}>
                <Form.Item
                  label="Número de Inscripción"
                  name="numeroInscripcion"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} lg={6}>
              <Form.Item label="Tipo de Persona" name="tipo" rules={[required]}>
                <Select
                  onChange={onChangeTipo}
                  enabled={state.fixedTipo || !actualizable}
                >
                  {tipos.map((g) => (
                    <Option key={g.value} value={g.value}>
                      {g.text.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={10}>
              <Form.Item
                label="Nombre / Razón Social"
                name="nombre"
                rules={[required]}
              >
                <Input enabled={!actualizable} />
              </Form.Item>
            </Col>
            {sexoRequired && (
              <Col xs={24} lg={4}>
                <Form.Item
                  label="Genero según DNI"
                  name="sexo"
                  rules={[required]}
                >
                  <Select enabled={!actualizable}>
                    {sexos.map((g) => (
                      <Option key={g.value} value={g.value}>
                        {g.text.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xs={24} lg={sexoRequired ? 4 : 8}>
              <Form.Item label="CUIT" name="cuil" rules={[required]}>
                <InputMask
                  mask="99 99999999 9"
                  maskChar=" "
                  enabled={!actualizable}
                >
                  {(inputProps) => <Input {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>

            {representanteRequired && (
              <Col xs={24}>
                <Form.Item
                  label="Representante Legal"
                  name="representanteLegal"
                >
                  <Input disabled={!actualizable} />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} lg={12}>
              <Form.Item
                label="Domicilio especial para notificar dentro del radio del gran Mendoza"
                name="direccionEspecial"
                rules={[required]}
              >
                <Input enabled={!actualizable} />
              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Número" name="numeroEspecial" rules={[required]}>
                <Input enabled={!actualizable} />
              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Piso" name="pisoEspecial">
                <Input enabled={!actualizable} />
              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Departamento" name="departamentoEspecial">
                <Input enabled={!actualizable} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label="Domicilio real"
                name="direccion"
                rules={[required]}
              >
                <Input enabled />
              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Número" name="numero" rules={[required]}>
                <Input enabled={!actualizable} />
              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Piso" name="piso">
                <Input enabled={!actualizable} />
              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Departamento" name="departamento">
                <Input enabled={!actualizable} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item label="Provincia" name="provincia" rules={[required]}>
                <Input enabled={!actualizable} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="Localidad" name="localidad" rules={[required]}>
                <Input enabled={!actualizable} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item label="Email" name="email" rules={[required]}>
                <Input disabled={!actualizable} />
              </Form.Item>
            </Col>
            {state.registrar && (
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Confirmar Email"
                  name="confirmEmail"
                  rules={[required]}
                >
                  <Input
                    autoComplete="new-password"
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </Form.Item>
              </Col>
            )}


<Col xs={24} lg={6}>
  <Form.Item label="Teléfono Fijo" name="telefono" rules={[required]}>
    <Input enabled={!actualizable} />
  </Form.Item>
</Col>

  <Col xs={24} lg={6}>
    <Form.Item label="Teléfono Celular" name="Celular" rules={[required]}>
      <Input enabled={!actualizable} />
    </Form.Item>
  </Col>

            <Col xs={24} lg={24}>
              <Form.Item
                label="Actividades Principales"
                name="actividadesPrimarias"
                rules={[required]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  maxTagCount="responsive"
                  enabled={!actualizable}
                >
                  {actividades.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item
                label="Actividades Secundarias"
                name="actividadesSecundarias"
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  maxTagCount="responsive"
                  disabled={!actualizable}
                >
                  {actividades.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {representanteRequired && (
              <Col xs={24}>
                <Form.Item
                  label="Representante Legal"
                  name="representanteLegal"
                >
                  <Input enabled={!actualizable} />
                </Form.Item>
              </Col>
            )}

            {sociosVisible && (
              <Socios
                list={socios}
                updateList={setSocios}
                readonly={!actualizable}
              />
            )}
          </Row>

          {actualizable && (
            <div className="form-actions">
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {!loading ? (state.registrar ? "Siguiente" : "Guardar") : ""}
                </Button>
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Datos;
