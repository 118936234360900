/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState } from "react";
import { Alert, Button, Checkbox, Col, Form, Modal, Row, Tooltip } from "antd";
import { FiDownload, FiEye } from "react-icons/fi";

import { Context } from "../../store/context";
import http from "../../services/http";
import { showSuccess } from "../../services/notification";

import Documento from "../ui/Documento";

import background from "./../../assets/images/background.png";

import "./LicitacionesForm.scss";
import Viewer from "react-viewer";
import { formatCurrency } from "./../../services/forms";

const LicitacionesForm = (props) => {
  const { current, onClose } = props;
  const [form] = Form.useForm();
  const inputRef = useRef(null);

  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const [documento, setDocumento] = useState();
  const [terms, setTerms] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);

  const termsUrl = process.env.REACT_APP_API_URL.slice(0, -3);

  const images = ["jpg", "jpeg", "bmp", "gif", "png", "pdf"];

  const onCloseModalPdf = async () => {
    setPdfVisible(false);
  }

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.archivo}`);
    if (file.extension === "pdf") {
      setPdfVisible(true)
    }
    else {

      setPreviewVisible(true);
    }
  };

  const onDocumentoAsociadoDownload = (file) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${file.archivo}`,
      "_blank"
    );
  };

  const onDocumentoClick = (item) => {
    setDocumento(item);
    inputRef.current.click();
  };

  const onFileChange = (e) => {
    const files = e.currentTarget.files;

    if (files.length === 0) {
      setDocumento();
      return;
    }

    let file = files[0];

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let nDocumentos = [...state.documentos];
      const index = nDocumentos.indexOf(documento);

      nDocumentos[index].archivos.push({
        contenido: reader.result.split(",")[1],
      });
      nDocumentos[index].archivo = reader.result.split(",")[1];
      nDocumentos[index].filename = file.name;
      nDocumentos[index].tamanio = file.size;
      nDocumentos[index].pendiente = false;

      const nameArray = file.name.split(".");
      if (nameArray[nameArray.length - 1]) {
        nDocumentos[index].extension = nameArray[nameArray.length - 1];
      }

      // Completado
      let completed =
        nDocumentos.filter((x) => x.obligatorio && x.archivo === undefined)
          .length === 0;

      setState({ ...state, documentos: nDocumentos, completed: completed });
      setDocumento();
    };

    e.currentTarget.value = "";
  };
  const onTermsClick = (value) => {
    let nDocumentos = [...state.documentos];
    if(nDocumentos.length == 0){
      setState({ ...state, documentos: nDocumentos, completed: true });
    }
    setTerms(value.target.checked);
  };

  const onSave = async (values) => {
    values.licitacion = current.id;
    values.documentos = state.documentos;

    setLoading(true);

    const response = await http.post("micrositio/inicio/solicitud", values);

    setLoading(false);
    setTerms(false);

    if (response && response.data.code === 0) {
      showSuccess(response.data.message);
      onClose();
    }
  };

  if (!current) return null;
  return (
    <>
    <Modal
      className="licitaciones-solicitud-form"
      centered
      destroyOnClose={true}
      footer={null}
      width={900}
      visible={current}
      onCancel={onClose}
    >
      <div
        className="image"
        style={{
          background: current.imagen
            ? `url(${process.env.REACT_APP_API_URL}/archivos/${current.imagen})`
            : `url(${background})`,
        }}
      >
        <div className="overlay">
          <div>
            <div className="header">Contratacion: #{current?.numero}</div>
            <div className="title">{current?.nombre}</div>
          </div>
          <div className="description">{current?.descripcion}</div>
        </div>
      </div>

      <div className="content">
        {!current.actividadesPermitidas && (
          <Alert type="warning">
            Recordar que al momento del pago, el adjudicado deberá tener sus
            constancias de inscripción impositivas (Afip e Ingresos Brutos) con
            las actividades actualizadas acorde al objeto de la contratación.
          </Alert>
        )}

        {current.repetida && (
          <Alert type="warning">
            Ya tenés una solicitud para esta línea de crédito. Revisa tu perfil
            para verificar su estado.
          </Alert>
        )}

        <div className="info">
          <div className="nombre">Objeto: {current?.nombre}</div>
          <div className="numero">Contratación N°: {current?.numero}</div>
          <div className="expediente">Expediente N°: {current?.expediente}</div>
          <div className="area">Area Solicitante: {current?.area}</div>
          <div className="valores">
            <div className="presupuesto">
              Presupuesto Oficial: {formatCurrency(current?.presupuesto)}
            </div>
            <div className="pliego">
              Pliego: {formatCurrency(current?.pliego)}
            </div>
            <div className="sellado">Sellado: {current?.utm} UTM</div>
          </div>
          <div className="apertura">
            <div className="fecha">
              Fecha de Apertura: {current?.aperturaFecha}
            </div>
            <div className="hora">Hora: {current?.aperturaHora}</div>
          </div>
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={onSave}
          style={{ width: "100%" }}
        >
          <input
            type="file"
            ref={inputRef}
            accept="application/pdf"
            onChange={onFileChange}
          />

          {state.documentosAsociados.length > 0 && (
            <div>
              <div className="documentos-title">Documentación asociada</div>
              <table className="table-documentos">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Tamaño</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {state.documentosAsociados.map((archivo, index) => (
                    <tr key={index}>
                      <td>{archivo.nombre}</td>
                      <td>{archivo.tamanio}</td>
                      <td>
                        <Tooltip title="Descargar">
                          <div
                            className="documento-download"
                            onClick={() => onDocumentoAsociadoDownload(archivo)}
                          >
                            <FiDownload />
                          </div>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {current.suscribir && state.documentos.length > 0 && (
            <div>
              <div className="documentos-title">Documentación pendiente</div>
              <div className="documentos">
                {state.documentos.map((x, index) => (
                  <Documento
                    key={index}
                    documento={x}
                    slidedown={false}
                    onUpload={onDocumentoClick}
                  />
                ))}
              </div>
            </div>
          )}

          {current.suscribir && (
            <Checkbox className="terminos" onChange={onTermsClick}>
              Acepto los
              <a
                href={`${termsUrl}Terminos_Condiciones.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Terminos y Condiciones
              </a>
            </Checkbox>
          )}

          {current.suscribir && (
            <div className="form-actions">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={!terms || !state.completed}
                >
                  {!loading && "Participar"}
                </Button>
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </Modal>

      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: "" }]}
      />

      {pdfVisible && (
        <React.Fragment>
          <Modal
            className=""
            centered
            destroyOnClose={true}
            footer={null}
            width={1200}
            visible={pdfVisible}
            onCancel={onCloseModalPdf}
          >
            <iframe title='Ver documento' src={previewUrl} frameBorder="0" width="98%" height="600"></iframe>

          </Modal>
        </React.Fragment>

      )}
      </>
  );
};

export default LicitacionesForm;
