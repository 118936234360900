import React from "react";

import background from "./../../assets/images/item-background.jpg";

import "./LicitacionesItem.scss";
import LicitacionesEstado from "./LicitacionesEstado";

const LicitacionesItem = ({ item, onClick }) => {
  const onItemClick = (item) => {
    onClick(item);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div
      className="licitaciones-item"
      style={{
        background: item.imagen
          ? `url(${process.env.REACT_APP_API_URL}/archivos/${item.imagen})`
          : `url(${background})`,
      }}
    >
      <div className="content">
        <div className="status">
          <LicitacionesEstado estado={item.estado} />
        </div>
        <div className="header">
          <div className="numero">#{item.numero}</div>
          <div className="expediente">EXP: {item.expediente}</div>
        </div>
        <div className="title"> {item.nombre}</div>
        <div className="subtitle">{item.area}</div>
        <div className="dates">
          <div className="subtitle">DESDE: {item.desde}</div>
          <div>
            <div className="subtitle">HASTA:{item.hasta}</div>
            {item.hastaProrroga && (
              <div className="subtitle">PRORROGA: {item.hastaProrroga}</div>
            )}
          </div>
        </div>
      </div>
      <div className="actions">
        <div className="button" onClick={() => openInNewTab(item.url)}>
          Ver más
        </div>
        {item.suscribir && (
          <div className="button" onClick={() => onItemClick(item)}>
            Participar
          </div>
        )}
      </div>
    </div>
  );
};

export default LicitacionesItem;
