import { Col, Row } from "antd";
import React from "react";

import logoFull from "./../../assets/images/logo-horizontal.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="container">
          <img src={logoFull} className="logo" alt="logo" />

          <Row>
            <Col xs={24} lg={8}>
              <div className="location">
                <div className="title">Ubicación</div>
                <br />
                Dirección de Compras y Suministros, 3er piso,
                <br />
              </div>
            </Col>
            <Col xs={24} lg={8}>
              <div className="phone">
                <div className="title">Teléfonos de Contacto</div>
                0261-4498238 / 0261-3072402
              </div>
            </Col>
            <Col xs={24} lg={8}>
              <div className="email">
                <div className="title">Mail de Contacto</div>
                registro.proveedores@muni.gob.ar
              </div>
            </Col>
          </Row>

          <div className="copyright">
            Copyright © {new Date().getFullYear()} |
            Todos los derechos reservados
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
