import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import { getUser } from "./../../services/security";

import "./Header.scss";

const Header = () => {
  const history = useHistory();

  const user = getUser();

  const onClickInicio = () => {
    history.push("/");
  };

  return (
    <div className="perfil-header">
      <div className="overlay">
        {user === null && (
          <div className="container">
            <h1>¡Bienvenido!</h1>
            <h4>
              Desde acá vas a poder darte de alta como proveedor de la
              Municipalidad.
            </h4>
            <div className="actions">
              <Button type="primary" size="large" onClick={onClickInicio}>
                Volver
              </Button>
            </div>
          </div>
        )}

        {user !== null && (
          <div className="container">
            <h1>¡Bienvenido {user?.nombre && ", " + user?.nombre}!</h1>
            <h4>
              Desde acá vas a poder administrar tu información y seguir tu
              participación, en nuestras contrataciones.
            </h4>
            <div className="actions">
              <Button type="primary" size="large" onClick={onClickInicio}>
                Ver Contrataciones Vigentes
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
