/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { FiSearch, FiUser } from "react-icons/fi";

import http from "../../services/http";

import SolicitudesDocumentos from "./SolicitudesDocumentos";
import SolicitudesDocumentosAdicionales from "./SolicitudesDocumentosAdicionales";

import "./SolicitudesParticipantes.scss";
import SolicitudesMensajes from "./SolicitudesMensajes";

const SolicitudesParticipantes = (props) => {
  const { solicitudId } = props;

  const [participantes, setParticipantes] = useState([]);
  const [current, setCurrent] = useState();

  useEffect(() => {
    async function getParticipantes() {
      const response = await http.get(
        `micrositio/perfil/solicitudes/${solicitudId}/participantes`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setParticipantes(data.participantes);
      }
    }
    getParticipantes();
  }, []);

  const onClickOpen = (item) => {
    setCurrent(item);
  };

  const onClickClose = () => {
    setCurrent();
  };

  return (
    <div className="solicitudes-participantes">
      <div className="header">
        <div className="icon">
          <FiUser />
        </div>
        <div className="data">
          <div className="title">Participantes</div>
          <div className="description">
            Otras personas que estan participando de esta contratación
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th className="name">Nombre</th>
            <th className="cuil">CUIT</th>
            <th className="date">Fecha</th>
            <th className="open"></th>
          </tr>
        </thead>
        <tbody>
          {participantes.map((participante, index) => (
            <tr key={index}>
              <td className="name">{participante.proveedor}</td>
              <td className="cuil">{participante.proveedorCuil}</td>
              <td className="date">{participante.fecha}</td>
              <td className="open">
                <div title="Abrir" onClick={() => onClickOpen(participante)}>
                  <FiSearch />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        className="solicitudes-participantes-form"
        centered
        destroyOnClose={true}
        footer={null}
        width={1200}
        visible={current}
        onCancel={onClickClose}
      >
        <div>
          <div className="header">
            <div className="title">{current?.proveedor}</div>
            <div className="description">{current?.proveedorCuil}</div>
          </div>
          {current && current.documentosVer && (
            <SolicitudesDocumentos
              solicitudId={current.id}
              agregar={current.documentosAgregar}
              actualizar={current.documentosActualizar}
            />
          )}
          {current && current.documentosAdicionalesVer && (
            <SolicitudesDocumentosAdicionales
              solicitudId={current.id}
              agregar={current.documentosAdicionalesAgregar}
            />
          )}
          {current && current.mensajesVer && (
            <SolicitudesMensajes
              solicitudId={current.id}
              agregar={current.mensajesAgregar}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SolicitudesParticipantes;
