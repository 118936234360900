/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import { ContextProvider } from "../store/context";

import Datos from "../components/perfil/Datos";
import Documentos from "../components/perfil/Documentos";
import Header from "../components/perfil/Header";
import DocumentosReadonly from "../components/perfil/DocumentosReadonly";

const RegistrarPage = ({ match }) => {
  const [state, setState] = useState({
    id: match.params.id,
    registrar: true,
    form: match.params.form ?? "datos",
    reload: false,
    fixedTipo: match.params.form === "datos",
  });

  return (
    <div className="registrar">
      <ContextProvider value={[state, setState]}>
        <Header />
        {state.form === "datos" && <Datos />}
        {state.form === "documentos" && (
          <React.Fragment>
            <DocumentosReadonly />
            <Documentos />
          </React.Fragment>
        )}
      </ContextProvider>
    </div>
  );
};

export default RegistrarPage;
