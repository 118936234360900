/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState } from 'react';
import { Button } from 'antd';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { FiDownload, FiThumbsDown, FiUpload } from 'react-icons/fi';
import { GoCheck } from 'react-icons/go';

import { Context } from '../../store/context';
import http from '../../services/http';

import './SolicitudBaja.scss';
import { showSuccess } from './../../services/notification';

const SolicitudBaja = () => {
  const inputRef = useRef(null);

  const [state] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [file, setFile] = useState();

  const onClickDownload = () => {
    window.open(
      process.env.REACT_APP_API_URL.slice(0, -3) + 'Solicitud_Baja.pdf',
      '_blank'
    );
    setDownloaded(true);
  };

  const onClickUpload = () => {
    inputRef.current.click();
  };

  const onChangeFile = (e) => {
    const files = e.currentTarget.files;
    if (files.length === 0) {
      setUploaded(false);
      return;
    }

    const file = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const nFile = {
        nombre: file.name,
        tamanio: file.size,
        contenido: reader.result.split(',')[1],
      };
      setUploaded(true);
      setFile(nFile);
    };
  };

  const onSubmit = async () => {
    setLoading(true);
    const url = `Micrositio/Perfil/${state.id}/Baja`;
    const response = await http.post(url, file);
    setLoading(false);

    if (response && response.data.code === 0) {
      showSuccess('La solicitud fue registrada exitosamente.');
    }
  };

  return (
    <div className="solicitud-baja">
      <div className="container">
        <div className="title">
          <h1>
            Solicitar Baja Voluntaria
            <FiThumbsDown />
          </h1>
          <p>
            Dale de baja a tu usuario para dejar de operar en la plataforma.
          </p>
        </div>

        <div className="instructions" onClick={onClickDownload}>
          <div className="instructions-icon">
            <FileIcon extension=".pdf" {...defaultStyles.pdf} />
          </div>
          <div className="instructions-content">
            <div className="instructions-title">1. Descargar Formulario</div>
            <div className="instructions-description">
              Para poder darte de baja es necesario que descargues y completes
              este formulario.
            </div>
          </div>
          <div
            className={`instructions-action ${downloaded ? 'completed' : ''}`}
          >
            {downloaded ? <GoCheck /> : <FiDownload />}
          </div>
        </div>

        <div className="instructions" onClick={onClickUpload}>
          <div className="instructions-icon">
            <FileIcon extension=".pdf" {...defaultStyles.pdf} />
          </div>
          <div className="instructions-content">
            <div className="instructions-title">
              2. Cargar formulario completo
            </div>
            <div className="instructions-description">
              Una vez que hayas completado y firmado el formulario, cargalo para
              que podamos procesarlo a la brevedad.
            </div>
          </div>
          <div className={`instructions-action ${uploaded ? 'completed' : ''}`}>
            {uploaded ? <GoCheck /> : <FiUpload />}
          </div>
          <input
            type="file"
            ref={inputRef}
            accept="application/pdf"
            onChange={onChangeFile}
          />
        </div>

        <div className="form-actions">
          <Button
            type="primary"
            loading={loading}
            disabled={!file}
            onClick={onSubmit}
          >
            {!loading && 'Enviar'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SolicitudBaja;
