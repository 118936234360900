import React from 'react';
import { Tag } from 'antd';

const LicitacionesEstado = (props) => {
  const { estado } = props;

  switch (estado) {
    case 1:
      return <Tag color="gold">Creada</Tag>;

    case 2:
      return <Tag color="lime">Vigente</Tag>;

    case 3:
      return <Tag color="green">Apertura</Tag>;

    case 4:
      return <Tag color="cyan">Post Apertura</Tag>;

    case 5:
      return <Tag color="blue">Pre Adjudicación</Tag>;

    case 6:
      return <Tag color="geekblue">Adjudicada</Tag>;

    case 7:
      return <Tag color="purple">Finalizada</Tag>;

    default:
      return <Tag>-</Tag>;
  }
};

export default LicitacionesEstado;
