/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import InputMask from "react-input-mask";

import { required } from "../../services/validation";

const SociosForm = (props) => {
  const { visible, readonly, list, onSave, onClose } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  const onFinish = async (values) => {
    values.nombre = values.nombre?.toUpperCase();
    values.cuit = values.cuit?.toUpperCase();

    let nList = [...list];
    nList.push(values);
    onSave(nList);
    onClose();
  };

  return (
    <Modal
      title="Agregar Socio"
      centered
      destroyOnClose={true}
      footer={null}
      width={600}
      visible={visible}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={[24]}>
          <Col xs={24}>
            <Form.Item label="Nombre" name="nombre" rules={[required]}>
              <Input maxLength={100} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="CUIT" name="cuit" rules={[required]}>
              <InputMask mask="99 99999999 9" maskChar=" " disabled={readonly}>
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Form.Item>
          </Col>
        </Row>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SociosForm;
