import React from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import Navbar from "./components/ui/Navbar";
import Footer from "./components/ui/Footer";
import InicioPage from "./pages/Inicio";
import PerfilPage from "./pages/Perfil";
import RegistrarPage from "./pages/Registrar";

import "./styles/App.scss";

import "./styles/Buttons.scss";
import "./styles/Cards.scss";
import "./styles/Container.scss";
import "./styles/DatePickers.scss";
import "./styles/Fonts.scss";
import "./styles/Forms.scss";
import "./styles/Inputs.scss";
import "./styles/Labels.scss";
import "./styles/Modals.scss";
import "./styles/Notifications.scss";
import "./styles/Pagination.scss";
import "./styles/Selects.scss";
import "./styles/Tags.scss";

const Layout = () => {
  return (
    <div className="app">
      <Router>
        <Navbar />
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route path="/registrar/:id?/:form?" component={RegistrarPage} />
          <Route path="/perfil" component={PerfilPage} />
          <Route path="/" exact component={InicioPage} />
          <Redirect to="/" />
        </AnimatedSwitch>
        <Footer />
      </Router>
    </div>
  );
};

export default Layout;
