import React from "react";

import "./Description.scss";

const Sectores = () => {
  return (
    <div className="inicio-description">
      <div className="container">
        <h1>REGISTRO DE PROVEEDORES DE LA MUNICIPALIDAD</h1>
        <p>
          Todas las personas (físicas, jurídicas, sociedades de hecho, etc) que
          deseen participar de contrataciones con la Municipalidad, deberán inscribirse en este Registro de Proveedores
          municipal.
        </p>
        <p>
          Para ello es muy importante descargar, leer y seguir todos los pasos
          descriptos en el “Instructivo Registro de Proveedores” que encuentra
          una vez que ingresa a “REGISTRATE”
        </p>
      </div>
    </div>
  );
};

export default Sectores;
