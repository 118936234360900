/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import { ContextProvider } from "../store/context";
import { getUser } from "./../services/security";

import Datos from "../components/perfil/Datos";
import Documentos from "../components/perfil/Documentos";
import Header from "../components/perfil/Header";
import Secciones from "../components/perfil/Secciones";
import Solicitudes from "./../components/perfil/Solicitudes";
import DocumentosReadonly from "./../components/perfil/DocumentosReadonly";
import SolicitudBaja from "../components/perfil/SolicitudBaja";

const PerfilPage = () => {
  const user = getUser();

  const [state, setState] = useState({
    id: user.proveedorId,
    registrar: false,
    reload: false,
    fixedTipo: true,
  });

  if (user === null) {
    window.location.replace("/");
    return <div></div>;
  }

  return (
    <div>
      <ContextProvider value={[state, setState]}>
        <Header />
        <Secciones />
        <Switch>
          <Route path="/perfil/datos">
            <Datos />
          </Route>
          <Route path="/perfil/documentos">
            <DocumentosReadonly />
            <Documentos />
          </Route>
          <Route path="/perfil/contrataciones" exact>
            <Solicitudes />
          </Route>
          <Route path="/perfil/solicitud-baja" exact>
            <SolicitudBaja />
          </Route>
        </Switch>
      </ContextProvider>
    </div>
  );
};

export default PerfilPage;
