/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Button, Modal, Progress, Collapse } from "antd";
import { FiFolder } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Context } from "./../../store/context";
import http from "./../../services/http";
import { showError, showSuccess } from "../../services/notification";

import Documento from "../../components/ui/Documento";

import "./Documentos.scss";

const { Panel } = Collapse;


const Documentos = () => {
  const history = useHistory();
  const inputRef = useRef(null);

  const [state, setState] = useContext(Context);
  const [documentos, setDocumentos] = useState([]);

  const [actualizable, setActualizable] = useState(false);
  const [documentosActualizables, setDocumentosActualizables] = useState(false);
  const [completo, setCompleto] = useState(false);

  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState({});
  const [progress, setProgress] = useState();

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        `micrositio/perfil/documentos?id=${state.id}`
      );

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDocumentos(data.documentos);
        setDocumentosActualizables(data.documentosActualizables);
        setActualizable(data.actualizable);
        setCompleto(data.completo);
      }
    }

    getData();
  }, [state.reload]);

  const onDocumentoClick = (item) => {
    setCurrent(item);
    inputRef.current.click();
  };

  const onFirmaCheck = async (file, current, reader) => {
    setProgress(1);

    const formdata = new FormData();
    formdata.append("file", file);

    const requestOptions = {
      method: "POST",
      body: formdata,
    };

    await fetch(`${process.env.REACT_APP_API_URL}/Licitaciones/signature/upload`, requestOptions)
      .then(response => {
        if (response.status === 200) {
          const values = {
            id: current.id.toString(),
            nombre: file.name,
            tamanio: file.size,
            contenido: reader.result.split(",")[1],
            proveedor: current.proveedor,
          };
          onFileUpload(values);
          showSuccess("El documento fue validado correctamente.");
        } else {
          showError("El documento no se encuentra firmado. intente nuevamente con otro!");
        }
      })
      .catch((error) => console.error(error));

    setProgress(null);

  };

  const onFileChange = (e, id) => {
    setProgress(1);

    const files = e.currentTarget.files;
    if (files.length === 0) {
      setCurrent({});
      setProgress(null);
      return;
    }

    let file = files[0];

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {

      if (id === 75) {
        onFirmaCheck(file, current, reader);
      } else {
        const values = {
          id: current.id.toString(),
          nombre: file.name,
          tamanio: file.size,
          contenido: reader.result.split(",")[1],
          proveedor: current.proveedor,
        };
        onFileUpload(values);
      }
      e.target.value = null;
    };
  };

  const onFileUpload = async (values) => {
    setProgress(1);

    const response = await http.postFile(
      "micrositio/perfil/documentos",
      values,
      setProgress
    );

    setProgress(null);

    if (response && response.data.code === 0) {
      setState({ ...state, reload: !state.reload });
      setCurrent({});
    }

    if (response && response.data.code === 3) {
      history.push("/");
    }

    inputRef.current.value = "";
  };

  const onFileDelete = (file) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este documento?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const response = await http.delete(
          `micrositio/perfil/documentos/${file.id}`
        );
        if (response && response.data.code === 0) {
          showSuccess("El documento fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onSave = async () => {
    if (!completo) {
      return;
    }

    setLoading(true);
    const response = await http.put(`micrositio/perfil/confirm?id=${state.id}`);
    setLoading(false);

    if (response && response.data.code === 0) {
      history.push("/");
      showSuccess("Tus datos se registraron exitosamente.");
    }
  };

  return (
    <div>
      <div id="perfil-documentos" className="perfil-documentos">
        <div className="container">
          <div className="title">
            <h1>
              Mis Documentos
              <FiFolder />
            </h1>
            <p>Carga la documentación requerida.</p>
          </div>

          <Collapse accordion>
            {documentos.map((item, index) => (
              <Panel header={item.nombre} key={index}>
                <Documento
                  documento={item}
                  slidedown
                  open
                  single={state.registrar}
                  onUpload={documentosActualizables ? onDocumentoClick : null}
                  onDelete={onFileDelete}
                />
                {progress && current.id === item.id && (
                  <div>
                    <span>Subiendo...</span>
                    <Progress percent={progress} />
                  </div>
                )}
                <input
                  type="file"
                  ref={inputRef}
                  accept="application/pdf"
                  onChange={(e) => onFileChange(e, item.documentoId)}
                />
              </Panel>
            ))}
          </Collapse>

        </div>

        <div className="form-actions">
          {actualizable && (
            <Button
              type="primary"
              onClick={onSave}
              disabled={!completo}
              loading={loading}
            >
              {!loading && "Enviar"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Documentos;
