import React, { useContext, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { FiAlertTriangle } from "react-icons/fi";

import { Context } from "./../../store/context";
import http from "./../../services/http";
import { setData } from "./../../services/security";
import { required } from "./../../services/validation";

import logo from "./../../assets/images/logo-horizontal.png";

const UpdateForm = ({ onClose }) => {
  const [form] = Form.useForm();
  const [state] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const onSave = async (values) => {
    setLoading(true);

    values.email = state.email;

    const response = await http.post("micrositio/login/update", values);

    if (response && response.data.code === 0) {
      const data = response.data.data;
      setData(data);
      window.location.reload();
    } else {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={0} lg={8}>
        <div className="image">
          <div className="overlay">
            <div>
              <div className="header">Login</div>
              <div className="title">Actualizar Contraseña</div>
            </div>
            <div className="description"></div>
          </div>
        </div>
      </Col>
      <Col xs={24} lg={16}>
        <div className="form">
          <Form form={form} layout="vertical" onFinish={onSave}>
            <img className="logo" src={logo} alt="logo" />

            <div className="alert warning">
              <div className="alert-icon">
                <FiAlertTriangle />
              </div>
              <div className="alert-content">
                Por razones de seguridad, necesitamos que{" "}
                <strong>actualices tu contraseña</strong> por una nueva.
              </div>
            </div>

            <Form.Item
              label="Contraseña nueva"
              name="password"
              rules={[required]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirmar contraseña nueva"
              name="confirm"
              rules={[required]}
            >
              <Input.Password />
            </Form.Item>

            <div className="form-actions">
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {!loading && "Actualizar"}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default UpdateForm;
