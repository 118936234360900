import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import { getUser } from "./../../services/security";

import Login from "./../login/Login";

import "./Header.scss";

const Header = () => {
  const history = useHistory();

  const user = getUser();
  const [visible, setVisible] = useState(false);

  const onClickLogin = () => {
    setVisible(true);
  };

  const onClickRegister = () => {
    history.push("/registrar");
  };
  const onClickContrataciones = () => {
    history.push("/perfil/contrataciones");
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="inicio-header">
      <div className="overlay">
        <div className="container">
          <h1>¡Bienvenidos!</h1>
          <h2>COMPRAS</h2>
          <h4>
            Un espacio para que puedas participar de nuestras contrataciones
          </h4>
          <div className="actions">
            {user === null && (
              <React.Fragment>
                <Button type="primary" size="large" onClick={onClickLogin}>
                  Ingresar
                </Button>
                <Button type="primary" size="large" onClick={onClickRegister}>
                  Registrate
                </Button>
              </React.Fragment>
            )}
            {user !== null && (
              <React.Fragment>
                <Button
                  type="primary"
                  size="large"
                  onClick={onClickContrataciones}
                >
                  Ver Mis Contrataciones
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <Login visible={visible} onClose={onClose} />
    </div>
  );
};

export default Header;
